<script setup>
  import EventDetailFightCard from '~/components/parts/events/EventDetailFightCard.vue';
  import EventDetailTournamentCard from '~/components/parts/events/EventDetailTournamentCard.vue';

  // --

  const { event } = defineProps(['event']);

  const { selectedProgramIndex } = useEventDetails();
  const selectedProgram = ref(event.programs[selectedProgramIndex.value]);

  function onProgramClick(programIndex) {
    selectedProgramIndex.value = programIndex;
    selectedProgram.value = event.programs[programIndex];
    console.log(selectedProgram.value.fight_cards[0].white_corner.left_image);
  }

  // --

  const cards = {
    fight_card: EventDetailFightCard,
    tournament_fight_card: EventDetailTournamentCard,
  };
</script>

<!----------------------------------------------------------------------------->

<template>
  <Section v-if="event.programs.length" autowrap wide>
    <ShowAnimation fadeOnly>
      <ul class="programs">
        <li v-for="(program, index) in event.programs">
          <button :class="{ active: program.title === selectedProgram.title }" @click="() => onProgramClick(index)">
            {{ program.title }}
          </button>
        </li>
      </ul>
    </ShowAnimation>

    <div v-for="program in event.programs">
      <BigTitle v-if="program.title === selectedProgram.title">
        {{ program.title }}
      </BigTitle>
    </div>

    <div class="title"></div>

    <div class="bottom-container">
      <div
        class="cut-outs"
        v-if="
          selectedProgram.fight_cards[0].type !== 'tournament_fight_card' &&
          selectedProgram.fight_cards[0].white_corner.left_image &&
          selectedProgram.fight_cards[0].black_corner.right_image
        "
        :key="selectedProgramIndex"
      >
        <Picture
          preset="fighter-cutout"
          :image="selectedProgram.fight_cards[0].white_corner.left_image"
          notLazy="true"
        />
        <Picture
          preset="fighter-cutout"
          :image="selectedProgram.fight_cards[0].black_corner.right_image"
          notLazy="true"
        />
      </div>
      <Foreground class="container">
        <GridItem :from="2" :to="11" class="fights">
          <component
            v-for="card in selectedProgram.fight_cards"
            :is="cards[card.type]"
            :data="card"
            :event="event"
            :key="Math.random()"
          ></component>
        </GridItem>
      </Foreground>
    </div>
  </Section>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  section {
    padding-top: px(90);
    padding-bottom: px(128);
    margin-bottom: -$content-padding-bottom;

    background-color: $black-3;

    .programs {
      margin-top: px(-22);
      margin-bottom: px(58);

      display: flex;
      justify-content: center;

      button {
        display: block;

        @include industry-demi(px(16));
        color: $white-20;

        padding: px(26) 0;
        width: px(161);
        border-bottom: px(2) solid $white-20;

        &.active {
          color: $white;
          border-color: $white;
        }
      }
    }

    .bottom-container {
      z-index: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      .cut-outs {
        display: flex;
        align-items: center;

        margin-top: -px(80);

        mask-image: linear-gradient(180deg, $black 75%, transparent 100%);
        -webkit-mask-image: linear-gradient(180deg, $black 75%, transparent 100%);

        img {
          width: 50%;
        }

        @include break-small {
          display: none;
        }
      }
    }

    .container {
      @include grid;
      width: 100%;
    }

    .fights {
      display: flex;
      flex-direction: column;
      gap: px(32);
    }

    @include break-medium {
      .fights {
        @include column(1, 12);
      }
    }
  }
</style>
